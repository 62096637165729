import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-US.svg'

export const localhost: Branding = {
  nationalBrandName: 'HeidelbergCement',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergcement.com',
  nationalLogoTitle: 'HeidelbergCement',

  contactEmail: 'support@localhost',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Hub',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',

  country: 'US',
  dispatchPhoneNumber: '09471 70753500',
  paymentGuideLink: 'https://www.heidelbergcement.com',
  digitalDocketsGuideLink: 'https://www.heidelbergcement.com'
}
