import {Branding} from '../Branding.types'

import LogoHeidelbergMaterialsRO from './icons/Logo-HM-US.svg'

export const hubHeidelbergMaterialsRo: Branding = {
  nationalBrandName: 'Heidelberg Materials Romania',
  nationalLogoUrl: LogoHeidelbergMaterialsRO,
  nationalUrl: 'https://www.heidelbergmaterials.ro',
  nationalLogoTitle: 'Heidelberg Materials Romania',
  contactEmail: 'contact.hub@ro.heidelbergmaterials.com',
  contactEmailSubject: 'Mail de la Hub - HeidelbergMaterials RO',
  pageTitle: 'Heidelberg Materials Romania',
  country: 'RO'
}
