import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-US.svg'

export const hubHeidelbergMaterialsNl: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'hub.heidelbergmaterials-benelux.com',
  nationalLogoTitle: 'Heidelberg Materials',
  contactEmail: 'InfoBENE@heidelbergmaterials.com',
  contactEmailSubject: 'Noteer de reden van uw vraag',
  pageTitle: 'Heidelberg Materials',
  country: 'NL'
}
