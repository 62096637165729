import {Branding} from '../Branding.types'

import LogoHansonUK from './icons/Logo-Hanson-UK.svg'

export const hubHansonCoUk: Branding = {
  nationalBrandName: 'Hanson',
  nationalLogoUrl: LogoHansonUK,
  nationalLogoTitle: 'Hanson',

  contactEmail: 'hconnect@uk.heidelbergmaterials.com',
  contactEmailSubject: 'Hub - Support Request - Hanson',

  pageTitle: 'Hanson',
  dispatchPhoneNumber: '+4403301234520',
  country: 'GB',
  termsAndConditionsOfSale: 'https://www.heidelbergmaterials.co.uk/en/terms-and-conditions-of-sale'
}
