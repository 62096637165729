import {Branding} from '../Branding.types'

import LogoBetongindustri from './icons/Logo-Betongindustri-SE.svg'

export const hubBetongindustriSE: Branding = {
  nationalBrandName: 'Betongindustri AB',
  nationalLogoUrl: LogoBetongindustri,
  nationalUrl: 'https://www.betongindustri.se/sv',
  nationalLogoTitle: 'Betongindustri AB',
  contactEmail: 'hub@betongindustri.se',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Betongindustri AB',
  country: 'SE'
}
