import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-US.svg'

export const empty: Branding = {
  nationalBrandName: 'nationalBrandName',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergcement.com',
  nationalLogoTitle: 'nationalLogoTitle',

  contactEmail: 'hchubdev@gmail.com',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'pageTitle',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',

  country: 'US'
}
