import {Branding} from '../Branding.types'

import LogoNorBetong from './icons/Logo-NorBetong-NO.svg'

export const hubNorbetongNO: Branding = {
  nationalBrandName: 'NorBetong AS',
  nationalLogoUrl: LogoNorBetong,
  nationalUrl: 'https://www.norbetong.no/no',
  nationalLogoTitle: 'NorBetong AS',
  contactEmail: 'hub@norbetong.no',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'NorBetong AS',
  country: 'NO'
}
