import {Branding} from '../Branding.types'

import LogoMQP from './icons/Logo-MQP.svg'

export const hubMqpCoUk: Branding = {
  nationalBrandName: 'Midland Quarry Products Ltd',
  nationalLogoUrl: LogoMQP,
  nationalLogoTitle: 'MQP',
  nationalUrl: 'https://www.mqp.co.uk/',
  contactEmail: 'hconnect@mqp.co.uk',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'MQP',
  country: 'GB',
  termsAndConditionsOfSale: 'https://www.mqp.co.uk/'
}
