import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-US.svg'

export const hubGorazdzePl: Branding = {
  nationalBrandName: 'Grupa Górażdże',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Grupa Górażdże',

  contactEmail: 'hub@gorazdze.pl',
  contactEmailSubject: 'Hub Górażdże: ',

  pageTitle: 'Grupa Górażdże',

  country: 'PL',
  dispatchPhoneNumber: '+48 777779010'
}
