import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-US.svg'

export const hubHeidelbergMaterialsBa: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.hub.heidelbergmaterials.ba',
  nationalLogoTitle: 'Heidelberg Materials',
  contactEmail: 'podrska-hub@heidelbergmaterials.com',
  contactEmailSubject: 'Poruka sa portala za kupce',
  pageTitle: 'Heidelberg Materials',
  country: 'BA'
}
