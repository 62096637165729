import {Branding} from '../Branding.types'

import LogoDKBeton from './icons/Logo-DK.svg'

export const hubDKBetonDk: Branding = {
  nationalBrandName: 'DK Beton A/S',
  nationalLogoUrl: LogoDKBeton,
  nationalUrl: 'https://www.dkbeton.dk/',
  nationalLogoTitle: 'DK Beton A/S',
  contactEmail: 'hub@dkbeton.dk',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'DK Beton A/S',
  country: 'DK'
}
