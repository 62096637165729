import {Branding} from '../Branding.types'

import LogoLehighHanson from './icons/Logo-Lehigh-Hanson-09-07-58-089.svg'

export const hubLehighHansonCom: Branding = {
  nationalBrandName: 'Lehigh Hanson',
  nationalLogoUrl: LogoLehighHanson,
  nationalLogoTitle: 'Lehigh Hanson',
  nationalUrl: 'https://www.lehighhanson.com/',
  contactEmail: 'hub-nam@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  hconnectLinkUrl: '//hub.hconnect.digital/nam/',
  safetyDataSheetLink: 'https://www.lehighhanson.com/resources/safety-data-sheets',

  pageTitle: 'Lehigh Hanson',

  country: 'US',

  termsAndConditionsOfSale: 'https://www.lehighhanson.com/quote-terms',

  paymentConfirmationMail: 'NAMAR.USAShared@heidelbergcement.com'
}
